<template>
  <div class="index ftfont">
    <Header @search="search"></Header>
    <div style="background:#f5f7fb;">
      <div class="wrapper">
        <div style="padding:30px 0;">
          <div class="wrapper">
            <div class="flex align-center justify-between" style="height:70px;">
              <div class="base-font-24 base-text text-bold">搜索内容</div>
            </div>
            <div>
              <el-row :gutter="20">
                <el-col :span="24" v-for="(item,index) in list" :key="index">
                  <div class="zcwnItem2">
                    <div style="height:30px;">
                      <div class="text-bold base-font-20 ellipsis">{{ item.title }}</div>
                    </div>
                    <div class="flex align-center justify-between">
                      <div class="base-font-16"><span>资源来源:{{ item.source }}</span><span
                          style="padding:0 10px;">|</span><span>发布时间:{{ item.showTime }}</span></div>
                      <div  @click="goNewsDetails(item.id)"  class="zcwjxzBtn" style="border:1px solid #ffffff;">查看详情</div>
                    </div>
                  </div>
                </el-col>

              </el-row>
            </div>
          </div>
          <div v-if="hasNextPage" style="text-align: center;">
            <div @click="more" class="text-center pointer moreBtn align-center">加载更多</div>
          </div>
        </div>
      </div>

    </div>
    <Footer></Footer>
  </div>

</template>

<script>
import {getWzContentPage} from "@/api/api";
import Header from './head/index.vue'
import Footer from './foot/index.vue'
	export default{
    components: {
      Header,
      Footer
    },
		data(){
			return{
        leftMenu: {childList: []},
        pathList: [],
        curMenu: {id: ''},
        pageNum: 1,
        pageSize: 10,
        hasNextPage: false,
        list: [],
        kindId: '',
        keywords:''
			}
		},

    mounted() {
      this.search(this.$route.query.keywords);
    },
		methods:{
      search(key){
        this.list=[];
        this.keywords=key;
        this.getWzContentPage();
      },
      more(){
        this.pageNum=this.pageNum+1;
        this.getWzContentPage();
      },
      getWzContentPage() {
        getWzContentPage({pageNum: this.pageNum, pageSize: this.pageSize,title:this.keywords}).then(res => {
          this.hasNextPage = res.hasNextPage;
          res.data.forEach((obj, index) => {
            obj.showTime = this.$moment(obj.clickTime).format('YYYY年MM月DD日');
            this.list.push(obj);
          })
        })
      },
      goNewsDetails(id) {
        this.$router.push({path: '/neswDetails?menuId=&id=' + id});
      }
		}
	}
</script>

<style>

.moreBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid #e6712c;margin: 0px auto;}
.moreBtn:hover{color:#ffffff;background:#e6712c;}
	.viewProBtn{height:46px;width:146px;border-radius:24px;line-height:48px;border:2px solid rgba(0, 113, 239, 1)}
	.viewProBtn:hover{color:#ffffff;background:rgba(0, 113, 239, 1);}

	.caseItemBox{width:100%;margin-bottom: 30px;cursor: pointer; position:relative;}
	.caseItemBox .viewBtn{width:100%;height:204px;position:absolute;background:rgba(0, 0, 0, 0.1);position: absolute;top:0;left:0;z-index:999;}
	.caseItemBox .viewBtn .bfBtn{width:60px;height:60px;border-radius:50%;background:rgba(0, 0, 0, 0.2);text-align:center;padding-top:15px;box-sizing: border-box;margin:72px auto;}
	.caseItemBox .viewBtn .bfBtn .el-image{width:30px;height:30px;margin:0 auto}
	.caseItemBox .caseImage{widht:100%;height:204px;overflow: hidden;}
	.caseItemBox .caseImage .el-image{
		width:100%;height:204px;
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
		}
	.caseItemBox .itemContent {
		border-bottom: 1px solid #f0f0f0;
		border-left: 1px solid #f0f0f0;
		border-right: 1px solid #f0f0f0;
		padding:10px 20px;
		box-sizing: border-box;
		line-height:32px;
	}
	.caseItemBox:hover {
		box-shadow: 0 0 30px #dddddd;
	}
	
	.caseItemBox:hover .caseImage .el-image{
		 transform:scale(1.5);
		-webkit-transform:scale(1.5);
		-ms-transform:scale(1.5);		
		-moz-transform:scale(1.5);		
		-o-transform:scale(1.5);
		
		transition: transform 0.7s;
		-webkit-transition: transform 0.7s;
	}
	.homeVideoModal {
	    background: rgba(0, 0, 0, 0.3);
	}
	
	.homeVideoModal .el-dialog {
	    background: none;
	    box-shadow: none;
	}
	
	.homeVideoModal .el-dialog--center .el-dialog__body {
	    padding: 0;
	}

.zcwjxzBtn {
  width: 77px;
  height: 30px;
  line-height: 30px;
  border-radius: 16px;
  color: #ffffff;
  font-size: 16px;
  background: #2D8CDB;
  text-align: center;
  cursor: pointer
}
	.homeVideoModal .el-dialog__headerbtn {
	    right: 0;
	    top: 0;
	}
	
	.homeVideoModal .el-dialog__headerbtn .el-dialog__close {
	    color: #FFFFFF;
	    font-size: 28px;
	}


.zcwnItem1 {
  background: #2D8CDB;
  color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
}

.zcwnItem2 {
  position: relative;
  background: #F8F8F8;
  padding: 20px;
  box-sizing: border-box;
  margin-top: 15px;
}

.zcwnItem2::before {
  content: '';
  display: inline-block;
  width: 4px;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #2D8CDB;
}

.tczsImage, .tczsImage .el-image {
  width: 371px;
  height: 236px
}

.tcltImage {
  width: 562px;
  height: 316px;
}

.tcltImage .el-image {
  width: 562px;
  height: 316px;
}

.tckxltBox {
  padding: 30px 0;
  background: #2681DE;
  background: -webkit-linear-gradient(top, #2681DE, #46B4CF);
  background: -o-linear-gradient(bottom, #2681DE, #46B4CF);
  background: -moz-linear-gradient(bottom, #2681DE, #46B4CF);
  background: linear-gradient(to bottom, #2681DE, #46B4CF);
}
</style>