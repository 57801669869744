<template>
	<div :class="isft==false?'':'ftfont'">
		<div class="baseHead">
			<div class="loginImage">
				<el-image :src="logo" fit="cover"></el-image>
			</div>
		</div>

		<div class="baseMenu">
			<div class="wrapper flex align-center justify-between">
				<div class="navItem" v-for="(item,index) in menuList" :key="index">
					<div class="menu relative">
						<a :href="item.url">{{ item.label }}</a>
						<div class="subMenu absolute">
							<ul>
								<li v-for="(item2,index2) in item.list"><a :href="item2.url">{{ item2.label }}</a></li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>


	</div>
</template>

<script>
	import {
		getMenu
	} from '@/api/api'
	export default {

		data() {
			return {
				logo: require('@/assets/image/logo.png'),
				tel: require('@/assets/image/tel.png'),
				isft: false,
				keywords: '',
				path: '',
				menuCurrenIndex: 0,
				phone: "",

				menuList: [
				
				],
			};
		},
		mounted() {
			this.keywords = this.$route.query.keywords;
			this.getMenu()
		},
		methods: {
			search() {
				if (this.keywords == "" || this.keywords == null) {
					this.$notify({
						title: '提示',
						message: '请输入搜索关键字',
						type: 'warning',
						duration: 2000
					});
				} else {
					this.path = this.$route.path;
					if (this.path.indexOf("search") > -1) {
						console.log("$emit:" + this.keywords);
						this.$emit("search", this.keywords);
					} else {
						this.$router.push({
							path: '/search',
							query: {
								keywords: this.keywords
							}
						});
					}

				}

			},
			// handleClick(tab) {
			//   console.log(tab.index,'3333');
			//   let _self = this
			//   _self.menuCurrenIndex = tab.index
			//   this.$router.push({path: _self.menuList[tab.index].url});
			// },
			getMenu() {
				getMenu(1).then(res => {
					var list = [];
					res.data.forEach((obj, index) => {
						if (obj.parentId == null || obj.parentId == "") {
							//一级目录
							if (obj.kind == 2) {
								//菜单
								list.push({
									id: obj.id,
									label: obj.name,
									url: obj.path
								});
							} else {
								//目录
								var chridList = [];
								res.data.forEach((chridObj, num) => {
									if (chridObj.parentId == obj.id) {
										chridList.push({
											id: chridObj.id,
											label: chridObj.name,
											url: chridObj.path,
											sort: chridObj.sort
										});

									}
								});
								chridList = chridList.sort((a, b) => b.sort - a.sort)
								list.push({
									id: obj.id,
									label: obj.name,
									list: chridList
								});
							}
						}
					});
					this.menuList = list;
					this.$nextTick(() => {
						this.phone = localStorage.getItem('phone')
					})

				})
			},

		}
	};
</script>

<style>
	.zt:hover {
		font-weight: bold;
	}

	.baseHead {
		width: 100%;
		height: auto;padding:40px 0;
		background: -webkit-linear-gradient(top,#e9f5ff,#fff);
		    background: -o-linear-gradient(bottom,#e9f5ff,#fff);
		    background: -moz-linear-gradient(bottom,#e9f5ff,#fff);
		    background: linear-gradient(180deg,#e9f5ff,#fff);
		background-size: 100% 100%;
	}


	.baseHead .loginImage {
		width: 650px;
		height: 89px;margin:0 auto;
	}

	.baseHead .loginImage .el-image {
		width: 100%;
		height: 89px;
	}


	.baseHeadRight {
		width: 460px;
		height: 50px;
		background: rgba(255, 255, 255, 0.6);
		padding: 0 3px 10px;
		box-sizing: border-box;
		border-radius: 25px;
	}

	.baseHeadRight input {
		width: 347px;
		height: 50px;
		border: none;
		outline: none;
		box-sizing: border-box;
		background: none;
	}

	.baseHeadRight .searchBtn {
		width: 100px;
		height: 44px;
		background: #924574;
		border-radius: 23px;
		line-height: 46px;
	}


	.topRight .tel {
		width: 46px;
		height: 46px;
	}

	.topRight .tel el-image {
		width: 46px;
		height: 46px;
	}



	.baseHead .homeMenu {
		width: 800px;
	}

	.baseHead .el-tabs__nav-wrap::after {
		display: none;
	}

	.baseHead .el-tabs__item {
		height: 64px;
		line-height: 64px;
		color: #FFFFFF;
	}

	.baseHead .el-tabs__header {
		margin-bottom: 0;
	}

	.baseMenu {
		width: 100%;
		height: 90px;
		line-height: 90px;
		background: #253783;
	}

	.baseMenu .navItem {
		font-size: 24px;
		color: #ffffff;
		height: 90px;
		line-height: 90px;
		cursor: pointer;
	}

	.baseMenu .navItem:hover,
	.baseMenu .navItem.checked {
		background: #118AE8;
	}

	.baseMenu .navItem .menu {
		position: relative;
		display: block;
		width: 100%;
		margin: 0;
		
	}

	.baseMenu .navItem .menu a {
		display: inline-block;
		width: 100%;
		height: 100%;
		text-decoration: none;
		color: #ffffff;
		padding:0 10px;
		
	}

	.baseMenu .navItem .menu .subMenu {
		position: absolute;
		top: 90px;
		left: 0;
		width: 100%;
		z-index: 99;
		display: none;
	}

	.baseMenu .navItem .menu:hover .subMenu {
		display: block;
	}

	.baseMenu .navItem .menu .subMenu ul {
		padding: 0;
		margin: 0;
		width: 100%;
		display: block;
		background: rgba(17, 35, 138, 0.95);
	}

	.baseMenu .navItem .menu .subMenu ul li {
		height: 50px;
		line-height: 50px;
		color: #ffffff;
		list-style-type: none;
		font-weight: normal;
		text-align: center;
		font-size: 18px;
	}

	.baseMenu .navItem .menu .subMenu ul li:hover {
		background: rgba(17, 138, 232, 0.6);
	}

	.baseMenu .navItem .menu .subMenu ul li+li {
		border-top: 1px dashed rgba(255, 255, 255, 0.4);
		cursor: pointer;
	}

	.baseMenu .navItem .menu .subMenu ul li a {
		display: block;
		width: 100%;
		height: 100%;
		text-align: center;
		padding: 0
	}

	.loginBtn {
		width: 99px;
		height: 40px;
		line-height: 40px;
		background: #0071ef;
		border-radius: 3px;
		margin-left: 10px;
	}

	.bannerBox {
		width: 100%;
		height: auto;
	}

	.banner {
		width: 100%;
		height: 323px;
		position: relative;
		background: #c5cedf;
	}

	.banner .swiper .swiper-slide {
		width: 100%;
		height: 323px;
	}

	.bannerBot {
		width: 100%;
		height: 60px;
		background: rgba(255, 255, 255, 0.2);
		position: absolute;
		left: 0;
		bottom: 0;
		z-index: 99;
	}

	.bannerBotWx {
		width: 260px;
		height: 60px;
		line-height: 60px;
	}

	.bannerBotWx:hover {
		color: #0071ef;
	}


	.tcHead {
		width: 100%;
		height: 210px;
		background: url('../../../assets/image/topbg.png') center no-repeat;
		background-size: auto 100%;
	}
</style>