<template>
  <div>
    <div class="footBox" style="background:#30303a;font-size:12px;line-height: 2.1;">
      <div class=" text-white">
        
        <div class="base-font-13" style="opacity:0.8;line-height:2.5;">
          <div>联系人：{{ sysConfigList['web_linker_name'] }} 电话：{{ sysConfigList['web_linker_phone'] }}</div>
          <div>邮箱：{{ sysConfigList['web_email'] }}</div>
          <div>地址：{{ sysConfigList['web_linker_address'] }}</div>
        </div>
		<div style="border-top:1px dashed rgba(255, 255, 255, 0.2);">
		  <div class="flex align-center pointer botLink padding-top-15" @click="goLink">
		    <el-image :src="ga" fit="cover"></el-image>
		    <div style="opacity:0.8;"><span class="padding-left-10 lb">{{ sysConfigList['web_bei_an'] }} </span></div>
		  </div>
		</div>
      </div>


    </div>
  </div>
</template>

<script>
import { getMenu, getSysConfigByFlage, webPathList } from "@/api/api";

export default {
  data() {
    return {
      sysConfigList: [],
      dzjg: require('@/assets/image/dzjg.png'),
      zfzc: require('@/assets/image/zfzc.png'),
      ga: require('@/assets/image/ga.png'),
      wb: require('@/assets/image/wb.png'),
      wechat: require('@/assets/image/wechat.png'),
      ewm: require('@/assets/image/ewm2.png'),
      pathList: [],
      menuList: [],
    }
  },
  mounted() {
    this.getMenu()
    this.webPathList();
    this.getSysConfigByFlage();
  },
  methods: {
    webPathList() {
      webPathList().then(res => {
        this.pathList = res.data;
      })
    },
    getSysConfigByFlage() {
      getSysConfigByFlage(5).then(res => {
        this.sysConfigList = res.data;
        localStorage.setItem('phone', res.data.web_linker_phone);
      })
    },
    handleSelect(key, keyPath) {
      console.log(key, keyPath);
    },
    goLink() {
      window.open('https://beian.miit.gov.cn/#/Integrated/index')
    },
    getMenu() {
      getMenu(1).then(res => {
        var list = [];
        res.data.forEach((obj, index) => {
          //一级目录
          if (obj.parentId == null || obj.parentId == "") {
            if (obj.kind == 2) {//菜单
              list.push({
                id: obj.id,
                label: obj.name,
                url: obj.path
              });
            } else {//目录
              var chridList = [];
              res.data.forEach((chridObj, num) => {
                if (chridObj.parentId == obj.id) {
                  chridList.push({
                    id: chridObj.id,
                    label: chridObj.name,
                    url: chridObj.path,
                    sort: chridObj.sort
                  });
                }
              });
              chridList = chridList.sort((a, b) => b.sort - a.sort)
              // 跳转 首个子菜单
              list.push({
                id: obj.id,
                label: obj.name,
                url: chridList[0].url
              });
            }
          }
        });
        this.menuList = list;
      })
    },
    toPath(path){
      this.$router.push({path});
    },
  }
}
</script>

<style>
.footRight .el-image {
  width: 24px;
  height: 24px;
  margin-left: 30px;
  cursor: pointer;
}

.yqljBox a,
.el-menu-item a {
  display: inline-block;
  text-decoration: none;
  color: #666666;
}

.friendLink {
  height: 60px;
}

.frendRight {
  width: 1098px;
}

.linkItem+.linkItem {
  margin-left: 40px;
}

.linkItem:hover {
  font-weight: bold;
}

.zxzxBtn {
  width: 96px;
  height: 36px;
  border: 1px solid;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 20px;
  text-align: center;
  line-height: 34px;
  cursor: pointer;
}

.zxzxBtn:hover {
  background: rgba(255, 255, 255, 0.2);
  border-color: rgba(255, 255, 255, 0.2);
}

.footMenuItem {
  padding-left: 30px;
}

.ewmBox {
  width: 90px;
  height: 90px;
  padding: 5px;
  background: #FFFFFF;
}

.ewmBox .el-image {
  width: 100%;
  height: 100%;
}

.lb:hover {
  opacity: 0.7;
}
.footBox{padding:30px;box-sizing: border-box;}
</style>